import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faAt, faHome, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react"

const Footer = () => (
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div className="col-12 col-md-6 px-3">
                    <ul class="list-group list-group-flush footer__link-list">
                        <li class="list-group-item footer__link-list-item text-center">
                            Férfikölcsönbe.hu | Ezermester szolgáltatás
                        </li>
                        <li class="list-group-item footer__link-list-item">
                            <FontAwesomeIcon icon={faUser} size="lg" className="pe-2" />
                            Hajdu Attila E.V.
                        </li>
                        <li class="list-group-item footer__link-list-item">
                            <FontAwesomeIcon icon={faHome} size="lg" className="pe-2" />
                            2213 Monorierdő, Hársfa utca 96.
                        </li>
                        <li class="list-group-item footer__link-list-item">
                            <FontAwesomeIcon icon={faPhone} size="lg" className="pe-2" />
                            +36-30-492-6734
                        </li>
                        <li class="list-group-item footer__link-list-item">
                            <FontAwesomeIcon icon={faAt} size="lg" className="pe-2" />
                            attilahajdu66@gmail.com
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-6 px-3">
                    <ul class="list-group list-group-flush footer__link-list">
                        <li class="list-group-item footer__link-list-item">
                            <a href="/gdpr" target="_blank">Adatvédelmi tájékoztató</a>
                        </li>
                        <li class="list-group-item footer__link-list-item">
                            <a href="https://facebook.com/ferfikolcsonbe" rel="noreferrer" target="_blank" >Facebook</a>

                        </li>
                    </ul>
                </div>

            </div>
            <hr></hr>
            <div class="text-center footer__promote">
                <small>Made with ❤️ by <a href="https://hajdux.hu" rel="noreferrer" target="_blank">hajdux.hu</a></small>
            </div>
        </div>
    </footer>
);

export default Footer;