import React from 'react';
import { Helmet } from 'react-helmet'
import { JsonLd } from './JsonLd';

const Seo = () => {
    return (
        <Helmet>
            <html lang="hu" />

            <title>Ezermester szolgálat Budapesten és vonzáskörzetében | Férfikölcsönbe.hu</title>
            <meta name="title" content="Ezermester szolgálat Budapesten és vonzáskörzetében | Férfikölcsönbe.hu" />
            <meta name="description" content="Otthoni javításokhoz és karbantartáshoz keres ezermestert? Állok rendelkezésére! Válasszon engem lakásfelújítási projektjéhez." />
            <meta name="keywords" content="ezermester, festés, mázolás, homlokzatfestés, hőszigetelés, gipszkartonozás, kőműves, burkoló, javítások, kerítésjavítás, mosógép bekötés, csap csere, polc felrakás, villanykapcsoló csere" />
            <meta name="robots" content="index, follow" />
            <meta name="revisit-after" content="1 Month" />

            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://ferfikolcsonbe.hu/" />
            <meta property="og:title" content="Ezermester szolgálat Budapesten és vonzáskörzetében | Férfikölcsönbe.hu" />
            <meta property="og:description" content="Otthoni javításokhoz és karbantartáshoz keres ezermestert? Állok rendelkezésére! Válasszon engem lakásfelújítási projektjéhez." />
            <meta property="og:image" content="https://ferfikolcsonbe.hu/site_image.jpg" />

            <meta property="fb:app_id" content="108418598050264" />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://ferfikolcsonbe.hu/" />
            <meta property="twitter:title" content="Ezermester szolgálat Budapesten és vonzáskörzetében | Férfikölcsönbe.hu" />
            <meta property="twitter:description" content="Otthoni javításokhoz és karbantartáshoz keres ezermestert? Állok rendelkezésére! Válasszon engem lakásfelújítási projektjéhez." />
            <meta property="twitter:image" content="https://ferfikolcsonbe.hu/site_image.jpg" />

            <link rel="canonical" href="https://ferfikolcsonbe.hu/" />
            <link rel="apple-touch-icon-precomposed" sizes="57x57" href="assets/apple-touch-icon-57x57.png" />
            <link rel="apple-touch-icon-precomposed" sizes="114x114" href="assets/apple-touch-icon-114x114.png" />
            <link rel="apple-touch-icon-precomposed" sizes="72x72" href="assets/apple-touch-icon-72x72.png" />
            <link rel="apple-touch-icon-precomposed" sizes="144x144" href="assets/apple-touch-icon-144x144.png" />
            <link rel="apple-touch-icon-precomposed" sizes="60x60" href="assets/apple-touch-icon-60x60.png" />
            <link rel="apple-touch-icon-precomposed" sizes="120x120" href="assets/apple-touch-icon-120x120.png" />
            <link rel="apple-touch-icon-precomposed" sizes="76x76" href="assets/apple-touch-icon-76x76.png" />
            <link rel="apple-touch-icon-precomposed" sizes="152x152" href="assets/apple-touch-icon-152x152.png" />
            <link rel="icon" type="image/png" href="assets/favicon-196x196.png" sizes="196x196" />
            <link rel="icon" type="image/png" href="assets/favicon-96x96.png" sizes="96x96" />
            <link rel="icon" type="image/png" href="assets/favicon-32x32.png" sizes="32x32" />
            <link rel="icon" type="image/png" href="assets/favicon-16x16.png" sizes="16x16" />
            <link rel="icon" type="image/png" href="assets/favicon-128.png" sizes="128x128" />
            <meta name="application-name" content="Férfikölcsönbe.hu" />
            <meta name="msapplication-TileColor" content="#FFFFFF" />
            <meta name="msapplication-TileImage" content="assets/mstile-144x144.png" />
            <meta name="msapplication-square70x70logo" content="assets/mstile-70x70.png" />
            <meta name="msapplication-square150x150logo" content="assets/mstile-150x150.png" />
            <meta name="msapplication-wide310x150logo" content="assets/mstile-310x150.png" />
            <meta name="msapplication-square310x310logo" content="assets/mstile-310x310.png" />

            <meta name="theme-color" content="#55c57a" />

        </Helmet>
    );
}

export default Seo;
