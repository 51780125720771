import React from 'react';
import Seo from "../Seo/Seo"
import Footer from "../Footer/Footer"
import Navbar from "../Navbar"

const Layout = ({ children }) => (
    <React.Fragment>
        <main>
            <Seo></Seo>
            <Navbar></Navbar>
            {children}
        </main>
        <Footer></Footer>
    </React.Fragment>
)

export default Layout;