import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import MenuIcon from '@images/icons/menu.svg';
import { faFacebookF, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NAV_ITEMS = [
    {
        name: "Főoldal",
        id: "home",
        hidden: true
    }, {
        name: "Rólam",
        id: "about",
        hidden: false
    }, {
        name: "Szolgáltatásaim",
        id: "services",
        hidden: false
    }, {
        name: "Árak",
        id: "pricing",
        hidden: false
    }, {
        name: "Kérdések",
        id: "faq",
        hidden: false
    }, {
        name: "Galéria",
        id: "gallery",
        hidden: true
    }, {
        name: "Kapcsolat",
        id: "contact",
        hidden: false
    }
];

class Navbar extends Component {
    constructor(props) {
        super(props);

        this.turnOnToggle = this.turnOnToggle.bind(this);
    }

    turnOnToggle() {
        console.log("")
    }

    getNavAnchorLink = item => (
        <AnchorLink className="nav-link m-2" offset="75" href={`#${item.id}`}>
            {item.name}
        </AnchorLink>
    );

    getNavList = () => (
        <Scrollspy
            className="navbar-nav ms-auto flex-nowrap"
            items={NAV_ITEMS.map(item => item.id)}
            currentClassName="active"
            offset={-80}
        >
            {NAV_ITEMS.map(item => (
                <li className="nav-item navigation__item" key={item.id}>
                    { !item.hidden ? this.getNavAnchorLink(item) : null}
                </li>
            ))}
            <li className="nav-item navigation__item" >
                <a href="https://facebook.com/ferfikolcsonbe" className="nav-link m-2" rel="noreferrer" target="_blank">
                    <FontAwesomeIcon icon={faFacebookSquare} size="lg" />
                </a>
            </li>

        </Scrollspy>
    )

    render() {
        return (
            <nav id="navbar" class="navbar navbar-expand-lg sticky-top navigation">
                <div class="container">
                    <a class="navbar-brand navigation__logo" href="/">
                        <StaticImage src="../../../images/logo2-min.png" height={50} alt="LOGO" placeholder="#55c57a" loading="eager" />
                    </a>
                    <div class="navbar-toggler navbar-toggler-right" onClick={this.turnOnToggle} type="div" data-bs-toggle="collapse" data-bs-target="#myNavbar">
                        <MenuIcon class="navigation__toggle-icon"></MenuIcon>
                    </div>

                    <div class="collapse navbar-collapse me-auto" id="myNavbar">
                        {this.getNavList()}
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar;
